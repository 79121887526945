<template>
  <div class="toast py-3 px-4 flex rounded-lg fixed top-4 right-4 w-[348px] items-start bg-neutral-white z-50">
    <div class="flex gap-2 w-full justify-between">
      <div class="flex gap-2 items-start">
        <div class="flex-none">
          <component :is="icon" class="h-4 w-4" />
        </div>
        <div class="flex flex-col text-[13px] font-normal leading-none">
          <div class="mb-1">
            <slot />
          </div>
          <span
            v-if="to"
            class="text-primary-700 text-[13px] hover:underline cursor-pointer font-semibold"
            @click="handleNavigation"
          >
            View
          </span>
        </div>
      </div>
      <PulseIconButton icon="IconClose" variant="secondary" size="default" @click="$emit('close')" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IconApprove, IconDanger, IconInfo, IconWarning } from '@pulse/components/icons';

const emit = defineEmits(['close']);

const props = defineProps({
  open: {
    default: false,
    type: Boolean,
  },
  to: {
    default: null,
    type: String,
  },
  type: {
    default: 'success',
    type: String as PropType<'success' | 'danger' | 'warning' | 'info'>,
  },
});

const icon = computed(() => {
  const map = {
    danger: IconDanger,
    info: IconInfo,
    success: IconApprove,
    warning: IconWarning,
  };

  return map[props.type];
});

function handleNavigation() {
  emit('close');
  navigateTo(props.to);
}
</script>

<style>
.toast {
  box-shadow: 0px 8px 16px 0px #0000001f;
}
</style>
